"use client"

import { createContext, useMemo, type PropsWithChildren } from "react"
import Polyglot from "node-polyglot"

import type { Nullish } from "~/@types/generics"
import type { Dictionary } from "~/providers/I18nProvider/constants"

interface ClientProviderProps {
  dictionary: Nullish<Dictionary>
  locale: string
  children: React.ReactNode
}

export function ClientProvider({ locale, dictionary, children }: ClientProviderProps) {
  return (
    <I18n locale={locale} phrases={dictionary ?? {}} allowMissing={true}>
      {children}
    </I18n>
  )
}

type T = (phrase: keyof Dictionary, options?: number | Polyglot.InterpolationOptions | undefined) => string
export const I18nContext = createContext<T | null>(null)

function I18n({
  children,
  ...polyglotOptions
}: PropsWithChildren<
  Required<Pick<Polyglot.PolyglotOptions, "locale" | "phrases">> & Omit<Polyglot.PolyglotOptions, "locale" | "phrases">
>) {
  const translate = useMemo(() => {
    const polyglot = new Polyglot(polyglotOptions)
    return polyglot.t.bind(polyglot)
  }, [polyglotOptions])

  return <I18nContext.Provider value={translate}>{children}</I18nContext.Provider>
}
