"use client"

import { useEffect, useRef, type HTMLAttributes, type RefObject } from "react"
import clsx from "clsx"
import { useEventListener, useLockedBody, useOnClickOutside } from "usehooks-ts"

import { sprinkles } from "~/styles/sprinkles.css"

export type ElementProps = HTMLAttributes<HTMLDivElement> & {
  current: JSX.Element | null
  removeCurrent(): void
  scrollDisabled?: boolean
  clickOutsideRef?: RefObject<HTMLElement>
  onRemove?(): void
  customDefaultRemove?(): void
}

/**
 * Element Component
 * @param {string} [props.className] - className for the element
 * @param {boolean} [props.scrollDisabled] - @default {true} - disabled scroll on element activation
 * @param {RefObject<HTMLElement>} [props.clickOutsideRef] - ref of children div for enable close on click outside
 * @param {void} [props.onClickOutside] - method to trigger when click outside
 * @example
 * ```tsx
 * import { Element } from '...'
 *
 * function Demo() {
 *  const containerRef = useRef<HTMLDivElement>(null)
 *
 *  return (
 *    <Element
 *      // if you want to custom element
 *      className={css.Element}
 *
 *      // if you want to not block scroll on element opened
 *      scrollDisabled={false}
 *
 *      // if you want to close element on click overlay
 *      clickOutsideRef={containerRef}
 *      >
 *      <div ref={containerRef}>
 *        // Our element container
 *      </div>
 *    </Element>
 *  )
 * }
 *
 * ```
 */
function Element({
  className,
  children,
  current,
  removeCurrent,
  scrollDisabled = true,
  clickOutsideRef,
  onRemove,
  customDefaultRemove,
  ...props
}: ElementProps) {
  const elementRef = useRef<HTMLDivElement>(null)

  const handleOnRemove = () => {
    const remove = () => (customDefaultRemove ? customDefaultRemove() : current && removeCurrent())
    remove()
    onRemove?.()
  }

  useLockedBody(scrollDisabled, "__next")
  useOnClickOutside(clickOutsideRef ?? elementRef, clickOutsideRef ? handleOnRemove : () => {}, "mousedown")

  useEventListener(
    "keydown",
    (e) => {
      if (e.code === "Escape") {
        handleOnRemove()
      }
    },
    elementRef
  )

  useEffect(() => {
    elementRef.current?.focus()
  }, [])

  return (
    <div
      ref={elementRef}
      className={clsx(
        sprinkles({
          inset: 0,
          position: "fixed",
        }),
        className
      )}
      tabIndex={0}
      {...props}
    >
      {children}
    </div>
  )
}

export default Element
