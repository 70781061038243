"use client"

import clsx from "clsx"

import createStackComponentManager, { type ElementProps } from "~/components/abstracts/StackComponentManager"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

const [PanelManager, AbstractPanel, usePanel] = createStackComponentManager()

type PanelProps = ElementProps & {
  zIndex?: Sprinkles["zIndex"]
  bgColor?: Sprinkles["backgroundColor"]
  childrenPosition?: "start" | "end"
}

export function Panel({
  zIndex = 50,
  childrenPosition = "end",
  bgColor = "blue-deep/50",
  className,
  ...props
}: PanelProps) {
  return (
    <AbstractPanel
      className={clsx(
        sprinkles({ zIndex, bgColor, display: "flex", justifyContent: `flex-${childrenPosition}` }),
        className
      )}
      {...props}
    />
  )
}

export { PanelManager, usePanel }
