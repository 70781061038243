import { useRef } from "react"
import { usePathname } from "next/navigation"
import { useUpdateEffect } from "usehooks-ts"

export function useOnRouteChange(callback: () => void) {
  //react 18 trigger one time all the useEffect in dev mode
  //but that's open and close the panel in dev mode
  const pathname = usePathname()
  let previousPathname = useRef(pathname)

  useUpdateEffect(() => {
    if (previousPathname.current !== pathname) {
      callback()
    }
    previousPathname.current = pathname
  }, [pathname])
}
