import { atom } from "jotai"
import { atomWithReset } from "jotai/utils"

const defaultCallbacks = {
  onAdd: (all: JSX.Element[], element: JSX.Element) => [element, ...all],
  onRemove: ([, ...all]: JSX.Element[]) => all ?? [],
  getCurrent: (all: JSX.Element[]) => all[0] ?? null,
}

export interface CreateStoreCallbacks {
  getCurrent?(all: JSX.Element[]): JSX.Element | null
  onAdd?(all: JSX.Element[], element: JSX.Element): JSX.Element[]
  onRemove?(all: JSX.Element[]): JSX.Element[]
}

function createStore(callbacks?: CreateStoreCallbacks) {
  const { onAdd, onRemove, getCurrent } = {
    ...defaultCallbacks,
    ...callbacks,
  }

  const allAtom = atomWithReset<JSX.Element[]>([])

  const currentAtom = atom((get) => getCurrent(get(allAtom)))

  const addAtom = atom(null, (get, set, popin: JSX.Element) => {
    set(allAtom, onAdd(get(allAtom), popin))
  })

  const removeCurrentAtom = atom(null, (get, set) => set(allAtom, onRemove(get(allAtom))))

  return {
    allAtom,
    currentAtom,
    addAtom,
    removeCurrentAtom,
  }
}

export default createStore
