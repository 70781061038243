"use client"

import clsx from "clsx"

import type { PropsWithClassName } from "~/@types/generics"
import type { TPanelMaterials } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/serializer"
import { Image } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import Icon from "~/components/abstracts/Icon"
import WrapperWithLink from "~/components/abstracts/WrapperWithLink"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./style.css"

type MaterialItemProps = PropsWithClassName<{
  item: NonNullable<TPanelMaterials>["items"][0]
}>
function MaterialItem({ className, item }: MaterialItemProps) {
  const t = useTranslate()

  return (
    <WrapperWithLink link={item.link} className={clsx(className, css.MaterialItem)}>
      <Image className={css.image} {...item.image} ratio="1/1" asPlaceholder />
      <div className={css.editoWrapper}>
        <span className={css.suptitle}>{item.suptitle}</span>
        <RichText className={css.title} render={item.title} />
        <RichText className={css.richtext} render={item.text} />
      </div>
      <div className={css.discoverWrapper}>
        <div className={showFrom("tablet", "block")}>
          <Icon className={clsx(css.icon)} name="ArrowRight" theme="blue-deep" width={14} height={14} />
        </div>
        <div className={hideFrom("tablet", "block")}>
          <span className={css.discoverLink}>
            <span className={css.discover}>{t("materials_discover")}</span>
            <Icon
              className={clsx(css.icon, css.arrowLeftIcon)}
              name="ArrowRight"
              theme="blue-deep"
              width={14}
              height={14}
            />
          </span>
        </div>
      </div>
    </WrapperWithLink>
  )
}

export { MaterialItem }
