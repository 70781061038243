"use client"

import { useRef } from "react"
import clsx from "clsx"

import Slider, { useSliderState } from "@unlikelystudio/react-slider"

import type { PropsWithClassName } from "~/@types/generics"
import { useOnRouteChange } from "~/hooks/useOnRouteChange"
import type { TPanelMaterials } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/serializer"
import { MaterialItem } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/components/MaterialItem"
import { Link } from "~/components/ui/Link"
import Icon from "~/components/abstracts/Icon"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"
import { Panel, usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"
import { hideFrom, showFrom } from "~/styles/utils/show-hide"

import * as css from "./style.css"

export type PanelMaterialsComponentProps = PropsWithClassName<{
  materialsItems: NonNullable<TPanelMaterials>
}>

function PanelMaterialsComponent({ className, materialsItems }: PanelMaterialsComponentProps) {
  const t = useTranslate()
  const panelMaterialsRef = useRef(null)
  const [{}, setSliderState] = useSliderState()
  const { removeCurrent, reset } = usePanel()

  const onPanelRemove = () => {
    removeCurrent()
  }

  useOnRouteChange(reset)

  return (
    <Panel className={clsx(className, css.panel)} zIndex={50} clickOutsideRef={panelMaterialsRef}>
      <div className={css.PanelMaterials} ref={panelMaterialsRef}>
        <div className={css.head}>
          <h3 className={css.titleHead}>{t("materials_title")}</h3>
          <button className={css.closeButton} aria-label={t("aria_materials_close_panel")} onClick={onPanelRemove}>
            <Icon className={sprinkles({ cursor: "pointer" })} name="Cross" theme="blue-deep" width={14} height={14} />
          </button>
        </div>
        <div className={clsx(css.content, hideFrom("mobile", "block"), showFrom("tablet", "block"))}>
          {materialsItems.items.map((item, i) => {
            return (
              <Link {...item.link} key={`link-material-item-${i}`}>
                <MaterialItem item={item} key={`materials-item-${i}`} />
              </Link>
            )
          })}
        </div>
        <Slider
          className={clsx(css.slider, hideFrom("tablet", "block"), showFrom("mobile", "block"))}
          containerClassName={css.sliderContainer}
          setSliderState={setSliderState}
          maxSlideIndexChange={1}
          snap
          autoBlockSlideIndexChange
        >
          {materialsItems.items.map((item, k) => (
            <MaterialItem item={item} key={`slider-material-item-${k}`} />
          ))}
        </Slider>
      </div>
    </Panel>
  )
}

export { PanelMaterialsComponent }
