"use client"

import { type HTMLAttributes } from "react"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import { PanelMaterialsComponent } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/components"
import { Link } from "~/components/ui/Link"
import { defaultLink, type TLink } from "~/components/ui/Link/_data/serializer"
import type { TRoundedCta } from "~/components/ui/RoundedCta/_data/serializer"
import { usePanel } from "~/managers/PanelManager"

import { sprinkles } from "~/styles/sprinkles.css"

type WrapperWithLinkProps = {
  link: Nullish<TRoundedCta | TLink>
} & HTMLAttributes<HTMLElement>

export default function WrapperWithLink({ link, ...props }: WrapperWithLinkProps) {
  const { add } = usePanel()

  const isLinkMaterialPanels = link && "materialPanels" in link && !!link.materialPanels

  function triggerPopin() {
    if (isLinkMaterialPanels && !!link.materialPanels) {
      add(<PanelMaterialsComponent materialsItems={link.materialPanels} />)
    }
  }

  const onlyLinkProps =
    link && "href" in link && link.href
      ? ({
          href: link?.href,
          target: link?.target,
          rel: link?.rel ?? defaultLink.rel,
          isExternal: link?.isExternal,
          obfuscate: link?.obfuscate ?? defaultLink.obfuscate,
        } satisfies TLink)
      : {}

  return (
    <Link
      {...onlyLinkProps}
      {...props}
      className={clsx(
        props.className,
        sprinkles({ cursor: isLinkMaterialPanels || onlyLinkProps?.href ? "pointer" : "default" })
      )}
      onClick={triggerPopin}
    />
  )
}
