import type { ComponentProps } from "react"
import clsx from "clsx"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import Account from "./assets/account.svg"
import ArrowLeft from "./assets/arrow-left.svg"
import ArrowRight from "./assets/arrow-right.svg"
import Burger from "./assets/burger.svg"
import Cart from "./assets/cart.svg"
import Chatbot from "./assets/chatbot.svg"
import ChevronDown from "./assets/chevron-down.svg"
import ChevronLeft from "./assets/chevron-left.svg"
import ChevronRight from "./assets/chevron-right.svg"
import ChevronUp from "./assets/chevron-up.svg"
import Cross from "./assets/cross.svg"
import EarthAfrica from "./assets/earth-africa.svg"
import Earth from "./assets/earth.svg"
import Eye from "./assets/eye.svg"
import Facebook from "./assets/facebook.svg"
import HelpCircle from "./assets/help-circle.svg"
import Instagram from "./assets/instagram.svg"
import Leaf from "./assets/leaf.svg"
import Logo from "./assets/Logo"
import Pause from "./assets/pause.svg"
import Pinterest from "./assets/pinterest.svg"
import Play from "./assets/play.svg"
import Search from "./assets/search.svg"
import Tick2 from "./assets/tick-2.svg"
import Tick from "./assets/tick.svg"
import Trash from "./assets/trash.svg"
import Upload from "./assets/upload.svg"
import VolumeMax from "./assets/volume-max.svg"
import VolumeOff from "./assets/volume-off.svg"
import * as css from "./styles.css"

export type DynamicIconProps = ComponentProps<"svg"> & { title?: string }

// TODO: Extract all icons to it own file
export const ICONS = {
  Account,
  ArrowRight,
  ArrowLeft,
  Burger,
  Cart,
  Chatbot,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ChevronLeft,
  Cross,
  EarthAfrica,
  Earth,
  Eye,
  HelpCircle,
  Leaf,
  Search,
  Tick,
  Upload,
  Instagram,
  Pinterest,
  Play,
  Pause,
  Facebook,
  VolumeMax,
  VolumeOff,
  Logo,
  Trash,
  Tick2,
} as const

export type IconName = keyof typeof ICONS

export interface IconProps extends DynamicIconProps {
  name: IconName
  theme?: Sprinkles["color"]
}

function Icon({ className, name, theme = "blue-deep", ...props }: IconProps) {
  const Component = ICONS?.[name] ?? null

  return Component && <Component className={clsx(css.Icon, className, sprinkles({ color: theme }))} {...props} />
}

export default Icon
