"use client"

import { useAtomValue, useSetAtom } from "jotai"
import { useResetAtom } from "jotai/utils"

import DefaultElement, { type ElementProps as DefaultElementProps } from "./components/Element"
import createStore, { type CreateStoreCallbacks } from "./store"

/**
 * Create Stack Component Manager
 * @param {function} [callbacks.getCurrent] - callback function to get current element
 * @param {function} [callbacks.onAdd] - callback function on add element
 * @param {function} [callbacks.onRemove] - callback function on remove element
 * @example
 * ```tsx
 * import createStackComponentManager from '...'
 *
 * const [PanelManager, AbstractPanel, usePanel] = createStackComponentManager()
 *
 * export function Panel({ className, ...props }: ElementProps) {
 *  return <AbstractPanel className={cx(css.Panel, className)} {...props} />
 * }
 *
 * export { PanelManager, usePanel }
 * ```
 */
function createStackComponentManager(callbacks?: CreateStoreCallbacks): [
  () => JSX.Element | null,
  (props: ElementProps) => JSX.Element | null,
  () => {
    items: JSX.Element[]
    current: JSX.Element | null
    add: (update: JSX.Element) => void
    removeCurrent: (update?: unknown) => void
    reset: () => void
  }
] {
  // Generate all Atoms instance
  const { allAtom, currentAtom, addAtom, removeCurrentAtom } = createStore(callbacks)

  // Create hooks to manage everything
  function useManager() {
    return {
      items: useAtomValue(allAtom),
      current: useAtomValue(currentAtom),
      add: useSetAtom(addAtom),
      removeCurrent: useSetAtom(removeCurrentAtom),
      reset: useResetAtom(allAtom),
    }
  }

  // Create componant that will manager current element
  function Manager() {
    const { current } = useManager()
    return current
  }

  // Create container compoment
  function Element(props: ElementProps) {
    const { current, removeCurrent } = useManager()

    return <DefaultElement current={current} removeCurrent={removeCurrent} {...props} />
  }

  return [Manager, Element, useManager]
}

export type ElementProps = Omit<DefaultElementProps, "current" | "removeCurrent">
export default createStackComponentManager
